<template>
  <v-expansion-panels v-model="show" class="b-column-group" focusable>
    <v-expansion-panel>
      <v-expansion-panel-header disable-icon-rotate>
        Revisão

        <template v-slot:actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="error" small v-if="items.length > 0">
                fa-solid fa-circle-exclamation
              </v-icon>
              <v-icon v-on="on" color="success" small v-else>
                fa-solid fa-check
              </v-icon>
            </template>
            <span>
              {{
                items.length > 0
                  ? 'Existem pendências para análise'
                  : 'Não existem pendências para análise'
              }}
            </span>
          </v-tooltip>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-form
          ref="form"
          lazy-validation
          v-model="valid"
          @submit.prevent="save"
        >
          <v-row dense>
            <v-col xl="12" lg="12" md="12" sm="12">
              <v-textarea
                label="Escreva aqui o objetivo da revisão"
                outlined
                required
                hide-details
                :rules="[required]"
                v-model="model.review"
              />
            </v-col>
            <v-col xl="12" lg="12" md="12" sm="12" class="text-right">
              <BaseButton
                type="submit"
                color="primary"
                title="Adicionar"
                :disabled="!valid"
              />
            </v-col>
          </v-row>

          <BaseTableList title="Revisões pendentes">
            <template v-slot:table>
              <v-data-table
                disable-sort
                hide-default-footer
                dense
                item-key="id"
                :headers="headers"
                :items="items"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary" @click="update(item)">
                        fa-solid fa-check
                      </v-icon>
                    </template>
                    <span>Resolver</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </template>
          </BaseTableList>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { ImplementationService } from '@/services/api/implementation';

export default {
  mixins: [rulesMixin],

  props: {
    step: {
      type: Number,
      require: true
    },
    implementationId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    items: [],
    valid: true,
    show: null,
    model: {
      review: null
    },
    headers: [
      { text: 'Descrição da revisão', value: 'review' },
      { text: 'Nome do usuário', value: 'userName' },
      { text: '', value: 'action', width: '10%' }
    ]
  }),

  created() {
    this.search();
  },

  methods: {
    async search() {
      try {
        const implementationService = new ImplementationService();
        const { status, data } = await implementationService.getReview({
          implementationId: this.implementationId,
          step: this.step
        });

        if (status === 200) {
          this.items = data;
        } else {
          this.items = [];
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const params = {
          ...this.model,
          ...{ implementationId: this.implementationId, step: this.step }
        };

        const implementationService = new ImplementationService();
        const { status } = await implementationService.createReview(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.$refs.form.reset();
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update(item) {
      try {
        const params = {
          ...item,
          ...{
            implementationId: this.implementationId,
            step: this.step,
            done: true
          }
        };

        const implementationService = new ImplementationService();
        const { status } = await implementationService.updateReview(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.$refs.form.reset();
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
